<template>
  <div>
    <meta itemprop="name" :content="product.title">
    <meta itemprop="productID" :content="product.id">
    <meta itemprop="price" :content="price">
    <meta itemprop="priceCurrency" :content="currency">
    <link itemprop="itemCondition" href="http://schema.org/NewCondition">
    <link itemprop="availability" href="http://schema.org/InStock">
    <meta itemprop="lowPrice" :content="price">
    <meta itemprop="highPrice" :content="Number(product.variants[0].compare_at_price) > 0 ? product.variants[0].compare_at_price : product.price">
    <div profile="http://www.heppnetz.de/grprofile/">
      <div xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns="http://www.w3.org/1999/xhtml"
        xmlns:foaf="http://xmlns.com/foaf/0.1/"
        xmlns:gr="http://purl.org/goodrelations/v1#"
        xmlns:xsd="http://www.w3.org/2001/XMLSchema#">

        <div about="#offering" typeof="gr:Offering">
          <div property="gr:name" :content="`${product.title} ${price}`" xml:lang="en" />
          <div property="gr:description" :content="description" xml:lang="en" />
          <div
            v-if="product.images && product.images.length"
            rel="foaf:depiction"
            :resource="get_correct_first_image(product.images)"
          />
          <div rel="gr:hasBusinessFunction" resource="http://purl.org/goodrelations/v1#Sell" />
          <div rel="gr:hasPriceSpecification">
            <div typeof="gr:UnitPriceSpecification">
              <div property="gr:hasCurrency" :content="currency" datatype="xsd:string" />
              <div property="gr:hasCurrencyValue" :content="price" datatype="xsd:float" />
            </div>
          </div>
          <div rel="gr:acceptedPaymentMethods"
            resource="http://purl.org/goodrelations/v1#PayPal" />
          <div rel="foaf:page" :resource="get_url()" />
        </div>
      </div>
    </div>
    <script type="application/ld+json">
      {{ json_ld_desc }}
    </script>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { CLIENT_STORE } from "../../../Client/constants/other"
import get_description from "../../../Shared/methods/get_description_from_product_description"
import get_message from "../../../Shared/methods/get_message_or_types_from_product_description"
import get_correct_first_image from "../../methods/get_correct_first_image"

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    selected_marketplace_language: {
      type: String,
      required: false,
      default: ""
    },
  },
  computed: {
    ...mapState(CLIENT_STORE, [
      "translations",
      "selected_language"
    ]),
    language() {
      return this.selected_marketplace_language || this.selected_language
    },
    title() {
      if (!this.product.translations) return this.product.title
      return this.product.translations[this.language] ? this.product.translations[this.language].title : ""
    },
    body_html() {
      if (!this.product.translations) return this.product.body_html
      return this.product.translations[this.language] ? this.product.translations[this.language].body_html : ""
    },
    /*
     * 
     */
    description() {
      return get_description(this.body_html)
    },
    /*
     * 
     */
    message() {
      return get_message(this.body_html)
    },
    json_ld_desc() {
      return JSON.stringify({
        "@type": "Product",
        image: get_correct_first_image(this.product?.images),
        url: this.get_url(),
        name: this.title
      })
    },
    /*
     * 
     */
    price() {
      if (!this.product.variants[0].translations) return this.product.variants[0].price
      return this.product.variants[0].translations ? this.product.variants[0].translations[this.language].price : ""
    },
  },
  mounted() {
    document.querySelector("meta[name='description']").content = this.product.title
    // TODO add keywords for product
    // document.querySelector("meta[name='keywords']").content = document.querySelector("meta[name='keywords']").content + ", " +
    //   this.product.type.join(", ") + this.product.filterable_info.reduce((total, current) => `${total}, ${current.value}`, "")
  },
	methods: {
    get_correct_first_image,
		get_url() {
			return window.location.href
    },
	}
}
</script>

<style>
</style>
